import TeamStatsLayout from "./teamStatsLayout";
import PlayerStatsLayout from "./playerStatsLayout";

export const StatsRoomConfig = [
	{
		name: 'Team Stats',
		component: TeamStatsLayout,
	},
	{
		name: 'Player Stats',
		component: PlayerStatsLayout,
	}
	

];