import React from 'react';
import axios from 'axios';
import PersonalMenu from './squadPlannerMenu';
import WaiverPlannerLayout from './waiverPlannerLayout';
import { SquadPlannerConfig } from './squadPlannerConfiguration'; // Import the config

class SquadPlannerLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leagueID: this.props.leagueID,
      wp_id: this.props.wp_id,
      entryID: this.props.entryID,
      selectedComponent: WaiverPlannerLayout,
      gw: this.props.gw,
      placeholder: true
    };
  }

  componentDidMount() {
    window.addEventListener('storage', this.handleStorageChange);
  }

  handleStorageChange = (event) => {
    // Implement your storage change handling logic here
  };

  onChange(component) {
    const selectedConfig = SquadPlannerConfig.find(
      (config) => config.component === component
    );
    const selectedName = selectedConfig ? selectedConfig.name : '';

    this.setState({ 
      selectedComponent: component,
      selectedName: selectedName
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.leagueID !== this.props.leagueID) {
      this.setState({ leagueID: this.props.leagueID });
      //this.initializeTable();
    }

    if (prevProps.entryID !== this.props.entryID) {
      this.setState({ entryID: this.props.entryID });
      //this.initializeTable();
    }
  }

  render() {
    const { selectedComponent, leagueID, gw, wp_id, entryID, selectedName } = this.state;
    const SelectedComponent = selectedComponent;

    const className = ['FDR', 'NEW Stats Room'].includes(selectedName) ? "" : "p-20 personal";

    return (
      <div>
        <PersonalMenu onChange={this.onChange.bind(this)} />
        <div className={className}>
          {SelectedComponent && (
            <SelectedComponent 
              leagueID={leagueID} 
              wp_id={wp_id} 
              gw={gw} 
              entryID={entryID} 
            />
          )}
        </div>
      </div>
    );
  }
}

export default SquadPlannerLayout;
