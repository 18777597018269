import React from 'react';
import axios from 'axios';
import { TroubleshootSharp, TryRounded } from '@mui/icons-material';

class PlayerStatsLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errorMessage: false,
      beginGW: 1, // Initialize with default values
      endGW: this.props.gw,
      placeholder: false,
      teamData: {},
      dataFilter: 'total', // Existing state for filter
      teamFilter: 'all', // New state for team filter
      positionFilter: 'all', // New state for position filter
      statusFilter: 'all', // New state for status filter
      sortedColumn: null,
      sortDirection: null,
      teamLoading: false, // Initialize teamLoading
      leagueID: this.props.leagueID
    };
  }

  componentDidMount() {
    if (!this.state.placeholder) { // Prefer using ! for boolean checks
      this.fetchStatsData();
    }
  }

  fetchStatsData = () => {
    this.setState({ loading: true }, () => {
      this.getStatsData()
        .then(() => {
          this.setState({ loading: false });
        })
        .catch(error => {
          // Handle errors if getStatsData fails
          console.error('Error fetching stats data:', error);
          this.setState({ loading: false });
        });
    });
  }

  getStatsData() {
    this.setState({ teamLoading: true });
    const { beginGW, endGW, leagueID } = this.state;
    return axios
      .post('/api/getPlayerStatsRoom', { beginGW, endGW, leagueID }) // Sending beginGW and endGW
      .then((res) => {
        this.setState({
          teamData: res.data.data,
          errorMessage: false,
          teamLoading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching stats data:', error);
        this.setState({ errorMessage: true, teamLoading: false });
      });
  }

  handleBeginGWChange = (event) => {
    const newBeginGW = parseInt(event.target.value, 10);
    this.setState({ beginGW: newBeginGW }, this.getStatsData);
  }

  handleEndGWChange = (event) => {
    const newEndGW = parseInt(event.target.value, 10);
    this.setState({ endGW: newEndGW }, this.getStatsData);
  }

  handleFilterChange = (event) => {
    this.setState({ dataFilter: event.target.value }, this.getStatsData);
  }

  handleTeamFilterChange = (event) => {
    this.setState({ teamFilter: event.target.value });
  }

  handlePositionFilterChange = (event) => {
    this.setState({ positionFilter: event.target.value });
  }

  handleStatusFilterChange = (event) => {
    this.setState({ statusFilter: event.target.value });
  }

  renderTable() {
    const { teamData, dataFilter, sortedColumn, sortDirection, teamLoading, teamFilter, positionFilter, statusFilter } = this.state;
    let filteredData = teamData[dataFilter] || [];

    // Apply Team Filter if not 'all'
    if (teamFilter !== 'all') {
      filteredData = filteredData.filter(item => item.team_short_name === teamFilter);
    }

    // Apply Position Filter if not 'all'
    if (positionFilter !== 'all') {
      filteredData = filteredData.filter(item => item.singular_name_short === positionFilter);
    }

    // Apply Status Filter if not 'all'
    if (statusFilter !== 'all') {
      filteredData = filteredData.filter(item => item.status === statusFilter);
    }

    if (filteredData.length === 0) {
      return <div>No data available.</div>;
    }

    // Define columns to exclude, including 'Player Optaid' and 'Status'
    const excludedColumns = ['Club', 'Club Id', 'Club Optaid', 'Player Optaid', 'status', 'Team'];

    // Define the list of columns that need to be formatted to one decimal place
    const decimalColumns = [
      'fpl_expected_assists',
      'fpl_expected_goal_involvements',
      'fpl_expected_goals',
      'fpl_expected_goals_conceded'
    ];

    // Extract column headers without underscores and excluding specified columns
    const sample = filteredData[0];
    const columns = Object.keys(sample)
      .filter(key => !key.includes('_') && !excludedColumns.includes(key) && key !== 'singular_name_short' && key !== 'team_short_name' && key !== 'web_name')
      .map(key => ({
        header: this.capitalizeHeader(key),
        accessorKey: key,
        className: 'text-center',
      }));

    // Define the first four columns: 'Player', 'Position', 'Team', 'Status'
    const tableColumns = [
      { header: 'Player', accessorKey: 'web_name', className: 'w-150' }, // First column
      { header: 'Position', accessorKey: 'singular_name_short', className: 'w-100' }, // Second column
      { header: 'Team', accessorKey: 'team_short_name', className: 'w-120' }, // Third column
      { header: 'Status', accessorKey: 'status', className: 'w-100' }, // Fourth column
      ...columns
    ];

    // Prepare table data with formatted numbers
    const tableData = filteredData.map(item => {
      let row = {
        web_name: item.web_name || '-', // First column: Player
        singular_name_short: item.singular_name_short || '-', // Second column: Position
        team_short_name: item.team_short_name || '-', // Third column: Team
        status: item.status || '-', // Fourth column: Status
      };
      columns.forEach(col => {
        const value = item[col.accessorKey];
        if (decimalColumns.includes(col.accessorKey) && typeof value === 'number') {
          row[col.accessorKey] = value.toFixed(1); // Format to one decimal place
        } else {
          row[col.accessorKey] = value !== undefined ? value : '-';
        }
      });
      return row;
    });

    // Sort the data based on sortedColumn and sortDirection
    const sortedData = [...tableData].sort((a, b) => {
      if (!sortedColumn || !sortDirection) return 0;
      let aValue = a[sortedColumn];
      let bValue = b[sortedColumn];

      if (aValue === '-' || aValue === Infinity) return 1;
      if (bValue === '-' || bValue === Infinity) return -1;

      const aValueIsNumber = !isNaN(parseFloat(aValue));
      const bValueIsNumber = !isNaN(parseFloat(bValue));

      if (aValueIsNumber && bValueIsNumber) {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
      }

      if (sortDirection === 'asc') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });

    return (
      <table className="h2h-table">
        <thead>
          <tr>
            {tableColumns.map(column => (
              <th
                className={column.className}
                key={column.accessorKey}
                onClick={() => this.onColumnHeaderClick(column.accessorKey)}
                style={{ cursor: 'pointer' }}
              >
                {column.header}
                {sortedColumn === column.accessorKey ? (
                  sortDirection === 'asc' ? ' ▲' : ' ▼'
                ) : null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {tableColumns.map(column => (
                <td
                  className={`${column.className} ${teamLoading ? 'grey-bg' : ''}`}
                  key={column.accessorKey}
                >
                  {row[column.accessorKey]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  // Utility function to capitalize the first letter of headers and replace underscores with spaces
  capitalizeHeader = (header) => {
    return header.charAt(0).toUpperCase() + header.slice(1).replace(/_/g, ' ');
  }

  onColumnHeaderClick = (accessorKey) => {
    const { sortedColumn, sortDirection } = this.state;
    if (sortedColumn === accessorKey) {
      this.setState({ sortDirection: sortDirection === 'asc' ? 'desc' : 'asc' });
    } else {
      this.setState({ sortedColumn: accessorKey, sortDirection: 'asc' });
    }
  }

  render() {
    const { teamData, loading, errorMessage, beginGW, endGW, placeholder, dataFilter, teamFilter, positionFilter, statusFilter } = this.state;

    if (placeholder) {
      return (
        <div className="p-20">
          <h2>FDR will be ready soon.</h2>
          <p>We have made major upgrades to our prediction model, and are finalising this before launching our new and improved FDR on Monday 29th July.</p>
        </div>
      );
    }

    if (loading) {
      return (
        <div className="p-20">
          <h2>Team Stats</h2>
          <div className="skeleton-container">
            <div className="skeleton-header"></div>
            <div className="skeleton-paragraph"></div>
            <div className="skeleton-block"></div>
          </div>
        </div>
      );
    }

    if (errorMessage) {
      return (
        <div>
          <h3>There was an error loading the data. Please refresh the page.</h3>
        </div>
      );
    }

    // Extract distinct team short names for the Team dropdown
    const teamOptions = ['all'];
    if (teamData[dataFilter]) {
      const uniqueTeams = [...new Set(teamData[dataFilter].map(item => item.team_short_name))];
      teamOptions.push(...uniqueTeams);
    }

    // Extract distinct positions for the Position dropdown
    const positionOptions = ['all'];
    if (teamData[dataFilter]) {
      const uniquePositions = [...new Set(teamData[dataFilter].map(item => item.singular_name_short))];
      positionOptions.push(...uniquePositions);
    }

    // Extract distinct statuses for the Status dropdown
    const statusOptions = ['all'];
    if (teamData[dataFilter]) {
      const uniqueStatuses = [...new Set(teamData[dataFilter].map(item => item.status))];
      statusOptions.push(...uniqueStatuses);
    }

    return (
      <div>
        <div className="flex g-10 ml-20 mt-20 flex-wrap">
          {/* Begin GW Dropdown */}
          <div className="flex">
            <div className="flex column g-10">
              <label htmlFor="beginGW">Start GW</label>
              <select
                id="beginGW"
                className="w-150 grey-border"
                value={beginGW}
                onChange={this.handleBeginGWChange}
              >
                {Array.from({ length: this.props.gw }, (_, i) => i + 1)
                  .filter(num => num <= endGW)
                  .map(num => (
                    <option key={num} value={num}>{num}</option>
                  ))}
              </select>
            </div>
          </div>

          {/* End GW Dropdown */}
          <div className="flex">
            <div className="flex column g-10">
              <label htmlFor="endGW">End GW</label>
              <select
                id="endGW"
                className="w-150 grey-border"
                value={endGW}
                onChange={this.handleEndGWChange}
              >
                {Array.from({ length: this.props.gw }, (_, i) => i + 1)
                  .filter(num => num >= beginGW)
                  .map(num => (
                    <option key={num} value={num}>{num}</option>
                  ))}
              </select>
            </div>
          </div>

          {/* Data Filter Dropdown */}
          <div className="flex">
            <div className="flex column g-10">
              <label htmlFor="dataFilter">Home/Away</label>
              <select
                id="dataFilter"
                className="w-150 grey-border"
                value={dataFilter}
                onChange={this.handleFilterChange}
              >
                <option value="total">Total</option>
                <option value="home">Home</option>
                <option value="away">Away</option>
              </select>
            </div>
          </div>

          {/* Team Filter Dropdown */}
          <div className="flex">
            <div className="flex column g-10">
              <label htmlFor="teamFilter">Team</label>
              <select
                id="teamFilter"
                className="w-150 grey-border"
                value={teamFilter}
                onChange={this.handleTeamFilterChange}
              >
                {teamOptions.map(team => (
                  <option key={team} value={team}>{team === 'all' ? 'All Teams' : team}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Position Filter Dropdown */}
          <div className="flex">
            <div className="flex column g-10">
              <label htmlFor="positionFilter">Position</label>
              <select
                id="positionFilter"
                className="w-150 grey-border"
                value={positionFilter}
                onChange={this.handlePositionFilterChange}
              >
                {positionOptions.map(position => (
                  <option key={position} value={position}>{position === 'all' ? 'All Positions' : position}</option>
                ))}
              </select>
            </div>
          </div>

          {/* New Status Filter Dropdown */}
          <div className="flex">
            <div className="flex column g-10">
              <label htmlFor="statusFilter">Status</label>
              <select
                id="statusFilter"
                className="w-150 grey-border"
                value={statusFilter}
                onChange={this.handleStatusFilterChange}
              >
                {statusOptions.map(status => (
                  <option key={status} value={status}>{status === 'all' ? 'All Statuses' : status}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="p-20">
          <div className="grey-border personal content-wrapper">
            <div className="table-wrapper vh-70">
              {this.renderTable()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlayerStatsLayout;
