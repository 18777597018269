import React, { useMemo } from 'react';
import axios from 'axios';
import { TroubleshootSharp, TryRounded } from '@mui/icons-material';

class TeamStatsLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errorMessage: false,
      beginGW: 1, // Initialize with default values
      endGW: this.props.gw,
      placeholder: false,
      teamData: {},
      dataFilter: 'total', // New state for filter
      sortedColumn: null,
      sortDirection: null,
      teamLoading: false, // Initialize teamLoading
    };
  }

  componentDidMount() {
    if (!this.state.placeholder) { // Prefer using ! for boolean checks
      this.fetchStatsData();
    }
  }

  fetchStatsData = () => {

    this.setState({ loading: true }, () => {
      this.getStatsData()
        .then(() => {
          this.setState({ loading: false });
        })
        .catch(error => {
          // Handle errors if getStatsData fails
          console.error('Error fetching stats data:', error);
          this.setState({ loading: false });
        });
    });
  }

  getStatsData() {
    this.setState({ teamLoading: true });
    const { beginGW, endGW } = this.state;
    return axios
      .post('/api/getTeamStatsRoom', { beginGW, endGW }) // Sending beginGW and endGW
      .then((res) => {
        this.setState({
          teamData: res.data.data,
          errorMessage: false,
          teamLoading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching stats data:', error);
        this.setState({ errorMessage: true, teamLoading: false });
      });
  }

  handleBeginGWChange = (event) => {
    const newBeginGW = parseInt(event.target.value);
    this.setState({ beginGW: newBeginGW }, this.getStatsData);
  }

  handleEndGWChange = (event) => {
    const newEndGW = parseInt(event.target.value);
    this.setState({ endGW: newEndGW }, this.getStatsData);
  }

  handleFilterChange = (event) => {
    this.setState({ dataFilter: event.target.value });
  }

  renderTable() {
    const { teamData, dataFilter, sortedColumn, sortDirection, teamLoading } = this.state;
    const filteredData = teamData[dataFilter] || [];

    if (filteredData.length === 0) {
      return <div>No data available.</div>;
    }

    // Define columns to exclude
    const excludedColumns = ['Club', 'Club Id', 'Club Optaid', 'team_short_name'];

    // Extract column headers without underscores and excluding specified columns
    const sample = filteredData[0];
    const columns = Object.keys(sample)
      .filter(key => !key.includes('_') && !excludedColumns.includes(key))
      .map(key => ({
        header: key,
        accessorKey: key,
        className: 'text-center',
      }));

    // Add 'Team' as the first column
    const tableColumns = [
      { header: 'Team', accessorKey: 'team_short_name', className: 'w-120' },
      ...columns
    ];

    const tableData = filteredData.map(item => {
      let row = {
        team_short_name: item.team_short_name || '-',
      };
      columns.forEach(col => {
        row[col.accessorKey] = item[col.accessorKey] !== undefined ? item[col.accessorKey] : '-';
      });
      return row;
    });

    const sortedData = [...tableData].sort((a, b) => {
      if (!sortedColumn || !sortDirection) return 0;
      let aValue = a[sortedColumn];
      let bValue = b[sortedColumn];

      if (aValue === '-' || aValue === Infinity) return 1;
      if (bValue === '-' || bValue === Infinity) return -1;

      const aValueIsNumber = !isNaN(parseFloat(aValue));
      const bValueIsNumber = !isNaN(parseFloat(bValue));

      if (aValueIsNumber && bValueIsNumber) {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
      }

      if (sortDirection === 'asc') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });

    return (
      <table className="h2h-table">
        <thead>
          <tr>
            {tableColumns.map(column => (
              <th
                className={column.className}
                key={column.accessorKey}
                onClick={() => this.onColumnHeaderClick(column.accessorKey)}
                style={{ cursor: 'pointer' }}
              >
                {column.header}
                {sortedColumn === column.accessorKey ? (
                  sortDirection === 'asc' ? ' ▲' : ' ▼'
                ) : null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {tableColumns.map(column => (
                <td
                  className={`${column.className} ${teamLoading ? 'grey-bg' : ''}`}
                  key={column.accessorKey}
                >
                  {row[column.accessorKey]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  onColumnHeaderClick = (accessorKey) => {
    const { sortedColumn, sortDirection } = this.state;
    if (sortedColumn === accessorKey) {
      this.setState({ sortDirection: sortDirection === 'asc' ? 'desc' : 'asc' });
    } else {
      this.setState({ sortedColumn: accessorKey, sortDirection: 'asc' });
    }
  }

  render() {
    const { teamData, loading, errorMessage, beginGW, endGW, placeholder, dataFilter } = this.state;

    if (placeholder) {
      return (
        <div className="p-20">
          <h2>FDR will be ready soon.</h2>
          <p>We have made major upgrades to our prediction model, and are finalising this before launching our new and improved FDR on Monday 29th July.</p>
        </div>
      );
    }

    if (loading) {
      return (
        <div className="p-20">
          <h2>Team Stats</h2>
          <div className="skeleton-container">
            <div className="skeleton-header"></div>
            <div className="skeleton-paragraph"></div>
            <div className="skeleton-block"></div>
          </div>
        </div>
      );
    }

    if (errorMessage) {
      return (
        <div>
          <h3>There was an error loading the data. Please refresh the page.</h3>
        </div>
      );
    }

    return (
      <div>
        <div className="flex g-10 ml-20 mt-20">
          {/* Begin GW Dropdown */}
          <div className="flex">
            <div className="flex column g-10">
              <label>Start GW</label>
              <select className="w-150 grey-border" value={beginGW} onChange={this.handleBeginGWChange}>
                {Array.from({ length: this.props.gw }, (_, i) => i + 1)
                  .filter(num => num <= endGW)
                  .map(num => (
                    <option key={num} value={num}>{num}</option>
                  ))}
              </select>
            </div>
          </div>

          {/* End GW Dropdown */}
          <div className="flex">
            <div className="flex column g-10">
              <label>End GW</label>
              <select className="w-150 grey-border" value={endGW} onChange={this.handleEndGWChange}>
                {Array.from({ length: this.props.gw }, (_, i) => i + 1)
                  .filter(num => num >= beginGW)
                  .map(num => (
                    <option key={num} value={num}>{num}</option>
                  ))}
              </select>
            </div>
          </div>

          {/* Data Filter Dropdown */}
          <div className="flex">
            <div className="flex column g-10">
              <label>Home/Away</label>
              <select className="w-150 grey-border" value={dataFilter} onChange={this.handleFilterChange}>
                <option value="total">Total</option>
                <option value="home">Home</option>
                <option value="away">Away</option>
              </select>
            </div>
          </div>
        </div>

        <div className="p-20">
          <div className="grey-border personal content-wrapper">
            <div className="table-wrapper vh-70">
              {this.renderTable()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamStatsLayout;
